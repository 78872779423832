/** @jsx jsx */

import OrtYaml from '../data/orte.yaml'
import AusstellungYaml from '../data/links.yaml'
import KategorienYaml from '../data/linksKategorien.yaml'
import Layout from '../components/layout'

import MySEO from '../components/seo'

import SelectBox from '../components/SelectBox'

import { Container,jsx} from 'theme-ui'
import { shuffle } from '../utils/shuffle'

const Links = () => {
    
    const orte = OrtYaml.content
    const kategorien = KategorienYaml.content
    const mthema= AusstellungYaml.content
    const thema =shuffle(mthema)
    
    if(orte&&kategorien&&thema)
    return(
        <Layout>
            <MySEO title="Regionale Informationen" description="Interessante Webseiten, Blogs und mehr im Oberen Mittelrheintal" />
           <Container>
 
           <SelectBox kategorien={kategorien} orte={orte} thema={thema} titel="Links" />
         </Container> 
        </Layout>
    )
    return null
}
        
export default Links