/** @jsx jsx */


import {Box,jsx,useThemeUI,Text} from 'theme-ui'
import Link from './Link'
import {useSpring,animated} from 'react-spring'
import MySEO from './seo'



const Daten= ({item})=>{
 const theme =useThemeUI()
 
  const props = useSpring({from:{opacity:0,marginBottom:20},to:{opacity:1,marginBottom:0},config:{duration:500}})
  
  
    return(
      <div sx={{mb:50,py:1,pt:3,width:["100%","100%"]}}>
        <MySEO title="Anzeige der Suchergebnisse auf K.O.M" />
      <animated.div style={props} sx={{
       

        justifyContent: `flex-start`,
        mt:0,
      
     
    }}>  
    {item.img && item.img!==""  ?

    // <Box sx={{
    //       backgroundImage: `url(${item.img})`,
    //       backgroundRepeat: 'no-repeat',
    //       backgroundSize: [`cover`,`cover`,`cover`],
    //       backgroundPositionY:`${item.oben?item.oben:'40%'}`,
          
    //       height:[350,450,250],
    //       width:["100%"],
    //       border:'1px solid green'
    // }}>
    //   {/* <img src={item.img} alt=""/> */}
    //   </Box>
    <figure sx={{position:'relative'}}>
      <img width="100%"   src={item.img} alt=""/>
      <figcaption sx={{fontSize:"10px",position:'absolute',bottom:10}} >{item.copy ? <div>{item.copy}</div>:<div sx={{marginBottom:0}}>&nbsp;</div>} </figcaption>
    </figure>
  :
  <figure sx={{position:'relative'}}>
  <img width="100%" src="/blanko.jpeg" alt=""/>
  <figcaption sx={{fontSize:"10px",position:'absolute',bottom:10}} >{item.copy ? <div>{item.copy}</div>:<div sx={{marginBottom:0}}>&nbsp;</div>} </figcaption>
</figure>
      
}
    

    
     

  {/* { 
     item.img===""  &&
     <Box sx={{
      backgroundImage: theme.colorMode==="dark"? `url("/hiw.jpeg")`:`url("/hi.jpeg")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: `cover`,
      backgroundPositionY:`${item.oben?item.oben:'40%'}`,
      height:[250,450,250],
      width:["100%"]
      }}/>
   }    */}
  
    <Box sx={{
      m:0,p:0,mb:80,
      objectFit:"cover",
      maxHeight:"600px",
      mt:-24,
      }}>
      <h2 sx={{color:`text`,mb:2,mt:"0",textTransform:'uppercase',fontSize:["1.3rem","1.4rem","1.3rem"]}}>{item.titel}</h2>
      {item.text1 && item.text1!=="" && <p sx={{m:0,pb:1,textTransform: `uppercase`,lineHeight:1.2,letterSpacing:`0.14rem`,color:`text`,fontSize:0}}>{item.text1} </p>}
      { <p sx={{fontSize:0,fontWeight:500,m:0,p:0,color:`text`}}>{item.beginn ? item.beginn:null} </p> }
      {item.text2 &&  item.text2!=="" && <p sx={{color:`text`,fontSize:0}}>{item.text2}</p> }
      {item.text3 &&  item.text3!=="" && <p sx={{color:`text`,fontSize:0}}>{item.text3}</p> }
      {item.text4 &&  item.text4!=="" && <p sx={{color:`text`,fontSize:0,marginBottom:10}}>{item.text4}</p> }
      {item.text5 &&  item.text5!=="" && <p sx={{color:`text`,fontSize:0,marginBottom:10}}>{item.text5}</p> }
      {item.text6 &&  item.text6!=="" && <p sx={{color:"#f00",fontWeight:700,fontSize:0,marginBottom:10}}>{item.text6}</p> }
    
      {item.iurl && item.iurl!=="" &&
      <Link style={{color:`text`}} to={item.iurl}><p sx={{color:`text`,m:0,p:0}}>  mehr...</p></Link>
      }
      {
        item.url && item.url!=="" &&
       <Link style={{color:`text`}} target="_blank" rel="noreferrer noopener" to={item.url}><p sx={{color:`text`,m:0,p:0}}> Webseite</p></Link>
      }
      {item.aktuell && item.aktuell==='0' ?<Box sx={{margin:0}}> <Text sx={{fontSize:'1rem',color:theme.colorMode==="dark"?'#ff0':'#ff0033'}}>nicht mehr aktuell</Text></Box>:null}
 
      
    </Box>
   
      </animated.div>
       

        </div>
    )
   
}

export default Daten