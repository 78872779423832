/** @jsx jsx */

import {useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import {Flex,Box,jsx,Select, Container} from 'theme-ui'
import Daten from './MyDaten'
import {animated,useSpring,config} from 'react-spring'
import Link from '../components/Link'
//import {shuffle} from '../utils/shuffle'


const SelectBox = ({kategorien,orte,zeiten,thema,titel}) => {
   
const props= useSpring({from:{margin:-33,opacity:0},to:{margin:10,opacity:1},config:config.gentle})
 
const [auswahl,setAuswahl] = useState(thema)
const [suchen,setSuchen] = useState(true)
const [k,setK] = useState("Alle")
//const [gefunden,setGefunden] = useState(true)


    function zeig(w,k){
        let wahl=null
        setK(w)
       setSuchen(true)
        
       
        
        if(w==="nach Ort" || w==="nach Kategorie") return null
        // if(w==="alle Orte")
        //   wahl=thema
        if(w==="alle Kategorien" || w==="alle Orte" || w==="alle Termine")
        return setAuswahl(thema)
          if(k==='kat')
            wahl = thema.filter(item => item.kat? item.kat.indexOf(w)!==-1:null)
          if(k==='ort')
           wahl = thema.filter(item => item.ort ? item.ort===w:null)
          // if(w==="alle Orte") return setAuswahl(thema)
         
          if(k==="zeiten")
          wahl = thema.filter(item => item.monat? item.monat.indexOf(w)!==-1:null)
         
          if(wahl.length>0) {
           
            setAuswahl(wahl)
            
           
        }else {
           setAuswahl(null)
            setSuchen(false)
        }
       
    }
   
   function Datenauswahl(){
       
       return(
        <Flex sx={{flexWrap:`wrap`,justifyContent:`flex-start`,alignItems:`space-around`}}>
             
        {
                 auswahl.map((item,index)=>{
                 return <animated.div style={props} sx={{
                   width:[`100%`,`90%`,`47%`],
                  
                 
                  
                 }}key={index}>{<Daten item={item} />}</animated.div>
                 })
                 
               }
       
         </Flex>
       )
   }

    function mach(){

       setAuswahl(thema)
       setSuchen(!suchen)
        
    }

    const  MeinBox = () => {
        const props= useSpring({from:{margin:-133,opacity:0},to:{margin:-10,opacity:1},config:config.slow})
       
        if(orte && kategorien)
        return(
      
        <Box sx={{width:[287,330],p:3,mb:4}}>

        <animated.div style={props}  >
            <Box sx={{mb:1}}>
             <Select sx={{color:'text'}} aria-label="Suche anhand von Kategorien" onChange={(e)=>zeig(e.target.value,'ort')}
                defaultValue={k}>
                {orte.map((item, index)=> {
                     return <option key={index}>{item.ort}</option>
                         })}
            </Select> 
        </Box> 
        {kategorien && kategorien.length>0 &&
        <Box sx={{mb:1}}>
           <Select sx={{color:'text'}}  aria-label="Suche anhand der Ortsangabe" onChange={(e)=>zeig(e.target.value,'kat')}
              defaultValue={k}>
              {kategorien.map((item, index)=> {
                   return <option key={index}>{item.kat}</option>
                       })}
          </Select> 
      </Box> 
      
        }

      {zeiten && zeiten.length>0 &&
        <Box sx={{mb:1}}>
           <Select sx={{color:'text'}} onChange={(e)=>zeig(e.target.value,'zeiten')}
              defaultValue={k}>
              {zeiten.map((item, index)=> {
                   return <option key={index}>{item.monat}</option>
                       })}
          </Select> 
       </Box> 
      
        }
        
        </animated.div>
        </Box>
        )

       
    }
  
    

  return(
      <Container>
           <h1><Link sx={{
              fontWeight:300,
              textDecoration: `none`,
              fontSize:[3,5,6],
              color:`text`,
              pl:2,
              letterSpacing:[`0rem`,`0.16rem`]
          }} to="/">{titel}</Link></h1>
         
          {  !suchen ?
            <animated.div style={props}  sx={{position:`relative`,display:`inline-block`}}>
             <button  sx={{border:`none`,background:`none`,}} onClick={()=>mach()}>
                 <span sx={{color:`text`,display:`flex`,cursor:`pointer`}}><FaSearch fontSize="26px"/> &nbsp; Suchen</span>
             </button>
             
             <animated.p style={props} sx={{pl:0,color:`text`,letterSpacing:`0.09rem`}}>Sorry, keine Daten gefunden ...</animated.p>
             
             </animated.div>
             
             : 
            
             <MeinBox/>
         }
         
        
        {auswahl && <Datenauswahl />}
          



   
    
   
 </Container>
  )

}


export default SelectBox
